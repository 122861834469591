import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Education = () => {
    return (
        <Layout>
            <Seo
                title="Bildung - BIKESAFE"
                description="Bis vor nicht allzu langer Zeit war es normal, dass Schüler zu Fuß oder mit dem Fahrrad zur Schule kamen.  Heute nehmen Sie hingegen den Bus oder werden von ihren Eltern gefahren. Einige Schulen, Hochschulen und Universitäten haben Fahrräder völlig verbannt. Ein erschreckender Gedanke.  Wie sollen Schüler und Studenten so ihre dringend benötigte körperliche Bewegung erhalten?"
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Bildung
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Radfahren tut gut</h3>
                        <p className="flow-text">
                            Bis vor nicht allzu langer Zeit war es normal, dass Schüler zu Fuß oder mit dem Fahrrad zur Schule kamen.  Heute nehmen Sie hingegen den Bus oder werden von ihren Eltern gefahren. Einige Schulen, Hochschulen und Universitäten haben Fahrräder völlig verbannt. Ein erschreckender Gedanke.  Wie sollen Schüler und Studenten so ihre dringend benötigte körperliche Bewegung erhalten?
                        </p>
                        <h3>Der Bike Safe Park-Guide</h3>
                        <p className="flow-text">
                            Manchmal ist die Planung von Abstellplätzen für Fahrräder ganz einfach: kaufen Sie einen Fahrradständer. Manchmal ist die Planung etwas aufwändiger. So oder so – Sie möchten das Meiste für Ihr Geld erhalten, ohne dabei Ihr Budget zu überschreiten. Bike Safe arbeitet mit Schulen, Hochschulen und Universitäten im gesamten Vereinigten Königreich zusammen, um Fragen rund ums Fahrradparken zu beantworten. Wir von Bike Safe kennen die Bedeutung qualitativ hochwertiger Parkplätze für die Fahrräder von Kindern und Jugendlichen, aber wir wissen auch, dass die richtige Beratung oft genauso wichtig ist.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/new-york.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                        <p>
                            <em className="flow-text">"Ich möchte mich bei dem gesamten Team von Bike Safe für Ihren ausgesprochen wettbewerbsfähigen und professionellen Service bedanken.  Sie haben Flexibilität und Verständnis für die Planungsanforderungen meiner Schule gezeigt."
                                <strong> Paul Jordan, Direktor, Thames View Vorschule, Dagenham</strong>.</em></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Ermitteln Sie Ihren Bedarf und Problembereiche</h3>
                        <p className="flow-text">
                            Eine Bestandsaufnahme zur aktuellen Fahrradnutzung ist ein wichtiger erster Schritt zur Bestimmung Ihres Bedarfs. Dazu können Sie zum Beispiel einfach eine Fahrradzählung an drei typischen Tagen bei schönem Wetter durchführen. Achten Sie bei dabei auf Folgendes:
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Sind die Fahrräder so geparkt, dass sie leicht zugänglich sind?</li>
                            <li className="border p-2 shade-on-hover">Stehen die Fahrräder dort, wo sie sollten, oder sind sie an Bäume, Pfosten, Bänke u. ä. gekettet?</li>
                        </ul>
                    </Col>
                    <Col sm="12" className="text-center">
                        <h3>Wählen Sie den richtigen Standort</h3>
                        <p className="flow-text">
                            Fahrradständer werden oft hinter oder neben Gebäude verbannt, wo sie außer Sicht sind. Dies schreckt Fahrradfahrer jedoch häufig ab und ist unpraktisch. Befinden sich die Ständer außerhalb der Sichtweite von Passanten, haben Diebe mehr Zeit ungestört zu stehlen. Platzieren Sie Fahrradständer am Haupteingang – ein gut sichtbarer Bereich, der zudem bequem für Fahrradfahrer ist. Das schreckt nicht nur Diebe ab, sondern wertet auch das Profil Ihres Fahrradprogramms auf.
                        </p>
                        <h3>Ziehen Sie einzigartige Lösungen in Betracht</h3>
                        <p className="flow-text">
                            Einer der Hauptgründe, warum Schüler nicht mit dem Fahrrad fahren, ist der unzureichende Schutz ihrer Fahrräder. Fahrradunterstände und Fahrradparksysteme bieten Fahrrädern im Freien einen höheren Schutz vor Witterungseinflüssen als unüberdachte Ständer. Eine Überdachung hält Regen und Schnee von Fahrrädern und ihren Fahrern fern, und ein Fahrradparksystem sorgt für zusätzliche Sicherheit.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/verticals.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Sicherheitsfragen</h3>
                        <p className="flow-text">
                            Verschiedene Fahrradständer und Installationsmethoden bieten verschiedene Sicherheitsstufen. Bei der Auswahl eines Fahrradständers für Ihre Schule, Hochschule oder Universität sollten Sie die folgenden Punkte berücksichtigen:
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Wie wichtig ist Sicherheit? Ist Diebstahl ein Thema an Ihrer Einrichtung?</li>
                            <li className="border p-2 shade-on-hover">Wie viele Fahrräder müssen Sie unterbringen und wie viel Platz ist verfügbar?</li>
                            <li className="border p-2 shade-on-hover">Auf welchem Untergrund wird der Ständer stehen? Asphalt, Rasen, Platten, Beton?</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Education;